import React, { useEffect, useState, useRef } from 'react';
import AccordionMenu from './components/AccordionMenu/AccordionMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import samTroyerBanner from './img/samtroyer banner.png';
import samTroyerIcon from './img/samtroyer icon.png';

// CSS
import './App.css'; 

// Components
import Home from './views/Home';
// import About from './views/About';
import Contact from './views/Contact';
// import Header from './views/Header';
import Portfolio from './views/Portfolio';
import Footer from './views/Footer';

const App =() => {

  const [activeSection, setActiveSection] = useState('home');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false); // Controls menu visibility

  const homeRef = useRef('');
  // const aboutRef = useRef('');
  const portfolioRef = useRef('');
  const contactRef = useRef('');
  
 // Track window resize to update image
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const homeOffset = homeRef.current.offsetTop;
      // const aboutOffset = aboutRef.current.offsetTop;
      const portfolioOffset = portfolioRef.current.offsetTop;
      const contactOffset = contactRef.current.offsetTop;

      // Calculate offsets for other sections

      const scrollPosition = window.scrollY;

      if (
        scrollPosition >= homeOffset &&
        scrollPosition < portfolioOffset
      ) {
        setActiveSection('home');
      // } else if (
      //   scrollPosition >= aboutOffset &&
      //   scrollPosition < portfolioOffset
      // ) {
      //   setActiveSection('about');
      } else if (
        scrollPosition >= portfolioOffset &&
        scrollPosition < contactOffset
      ) {
        setActiveSection('portfolio');
      } else if (
        scrollPosition >= contactOffset
      ) {
        setActiveSection('contact');
      }
      // Check and set active section for other offsets
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const headerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',  
    background: '#777',
    color: '#fff',
    textAlign: 'center',
    padding: '20px',
    flexDirection: isMobile ? 'column' : 'row'
  };

  

  return (
    <div className="App">
      <header style={headerStyle}>
        <div style={headerContainer}>
          <img
            src={isMobile ? samTroyerIcon : samTroyerBanner}
            alt="Sam Troyer Header"
            width={isMobile ? 250 : 650}
          />
          {isMobile && (
            <FontAwesomeIcon
              icon={menuOpen ? faTimes : faBars}
              style={hamburgerIcon}
              onClick={() => setMenuOpen(!menuOpen)}
            />
          )}
        </div>

        {(menuOpen || !isMobile) && (
          <nav style={isMobile ? mobileNav : desktopNav}>
            <ul style={ulStyle}>
              <li className={activeSection === 'home' ? 'active' : ''} onClick={() => scrollToSection(homeRef)}>
                Home
              </li>
              <li className={activeSection === 'portfolio' ? 'active' : ''} onClick={() => scrollToSection(portfolioRef)}>
                Portfolio
              </li>
              <li className={activeSection === 'contact' ? 'active' : ''} onClick={() => scrollToSection(contactRef)}>
                Contact
              </li>
            </ul>
          </nav>
        )}
      </header>

      <main>
        <section ref={homeRef}>
          <Home id="home" />
        </section>
        <section ref={portfolioRef}>
          <Portfolio id="portfolio" />
        </section>
        <section ref={contactRef}>
          <Contact id="contact" />
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default App;

const headerContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const hamburgerIcon = {
  fontSize: '30px',
  cursor: 'pointer',
};


const ulStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
};

const mobileNav = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  cursor: 'pointer',
  background: '#444',
  padding: '15px',
  textAlign: 'center',
};

const desktopNav = {
  display: 'flex',
  marginRight: 60,
  gap: '20px',
};
