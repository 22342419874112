import React, { useEffect, useState } from 'react';
import './css/Portfolio.css'
import ymFeatureGraphic from '../img/yogamatt/feature/ym-feature-graphic.png'
import catAppFeatureGraphic from '../img/cat-app/Home Page 01.png'
import Grid from '../components/Grid/Grid';

const Portfolio = () => {
  
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const items = [
      { 
        id: 1, 
        image: ymFeatureGraphic, 
        text: 'Yogamatt is a location-based booking service helping to add bookings to your classes, and inspiring new classes in new places.',
        link: 'https://yogamatt.info'
      },
      { 
        id: 2, 
        image: catAppFeatureGraphic, 
        text: 'Cat App is an app that finds and locates cats in your area.',
        link: null
      },
      { 
        id: 3, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 3', 
        link: null 
      },
      { 
        id: 4, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 4', 
        link: null 
      },
      { 
        id: 5, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 5', 
        link: null 
      },
      { 
        id: 6, 
        image: 'https://via.placeholder.com/150', 
        text: 'Item 6', 
        link: null 
      },
      // Add more items as needed
    ];

    return (
      <section style={portfolioStyle}>
          <h2>Portfolio</h2>
          <div style={isMobile ? mobileGridStyle : desktopGridStyle}>
              {items.map(item => (
                  <div key={item.id} style={cardStyle}>
                      <img
                          src={item.image}
                          alt={item.text}
                          style={isMobile ? mobileImageStyle : desktopImageStyle}
                      />
                      <p>{item.text}</p>
                      {item.link && <a href={item.link} target="_blank" rel="noopener noreferrer">View Project</a>}
                  </div>
              ))}
          </div>
      </section>
  );
};

// Styles
const portfolioStyle = {
  background: '#f9f9f9',
  padding: '50px 20px',
  textAlign: 'center'
};

const desktopGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns on desktop
  gap: '20px',
  justifyContent: 'center'
};

const mobileGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr', // Single column on mobile
  gap: '15px',
  justifyContent: 'center'
};

const cardStyle = {
  background: '#fff',
  padding: '15px',
  borderRadius: '10px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  textAlign: 'center'
};

const desktopImageStyle = {
  width: '100%',
  maxWidth: '300px',
  height: 'auto',
  borderRadius: '10px'
};

const mobileImageStyle = {
  width: '100%',
  maxWidth: '250px',
  height: 'auto',
  borderRadius: '10px'
};

export default Portfolio;